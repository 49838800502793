import React from "react"
import style from "./style.module.scss"

export default function Prices({ tier, term, currency }) {
  const prices = {
    plus: {
      eur: {
        symbol: '€',
        monthly: 6.5,
        monthlyDisc: 5.2,
        yearly: 52,
        yearlyDisc: 26,
      },
      usd: {
        symbol: '$',
        monthly: 6.5,
        monthlyDisc: 5.2,
        yearly: 60,
        yearlyDisc: 30,
      },
      php: {
        symbol: 'P',
        monthly: 320,
        monthlyDisc: 256,
        yearly: 3000,
        yearlyDisc: 1500,
      },
    },
    pro: {
      eur: {
        symbol: '€',
        monthly: 5,
        monthlyDisc: 4,
        yearly: 88,
        yearlyDisc: 44,
      },
      usd: {
        symbol: '$',
        monthly: 6,
        monthlyDisc: 5,
        yearly: 100,
        yearlyDisc: 50,
      },
      php: {
        symbol: 'P',
        monthly: 320,
        monthlyDisc: 250,
        yearly: 5000,
        yearlyDisc: 2500,
      },
    },
    unlimited: {
      eur: {
        symbol: '€',
        monthly: 10,
        monthlyDisc: 8,
        yearly: 140,
        yearlyDisc: 70,
      },
      usd: {
        symbol: '$',
        monthly: 12,
        monthlyDisc: 10,
        yearly: 160,
        yearlyDisc: 80,
      },
      php: {
        symbol: 'P',
        monthly: 640,
        monthlyDisc: 500,
        yearly: 7800,
        yearlyDisc: 3900,
      },
    },
  }

  return (
    <div>
      <div className={style.price}>
        <span className={style.strikethrough + " text-muted"}>
          {prices[tier][currency]['symbol'] + prices[tier][currency][term].toFixed(0)}
        </span>{" "}
        <span>
          {prices[tier][currency]['symbol'] + prices[tier][currency][term + 'Disc'].toFixed(0)}
        </span>
      </div>
      <div className={style.term + " pt-1 text-muted"}>
        { 
          term === 'monthly' ? 
          <span>/ MONTH</span> :
          <span>1 YEAR</span>
        }
      </div>
    </div>
  )
}
