import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className={style.promo}>
      <div className="op__block">
        <div className="container-fluid">
        <a href="https://app.nursingexampro.com/#/auth/register">
          <div
            className={style.promo__notification}
            data-aos="fade-down"
            data-aos-delay="0"
          >
            <span>
              <span role="img" aria-label="">
                👉
              </span>{" "}
              Limited Time Offer! GET 50% OFF! Sign Up Now!
            </span>
          </div>
        </a>
          <div className={style.promo__inner}>
            <div className="row text-left">
              <div className="col-md-6">
                <h2 data-aos="fade-right">
                  Over 10,000
                </h2>
                <h1>
                  <strong>NCLEX Practice Questions</strong>
                </h1>
                <ul
                  className={style.promo__list}
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <li>
                    <span
                      className={`${style.promo__list__icon} ${style.promo__list__icon__html}`}
                    >
                      <img
                        src="/resources/img/icons/check-solid.svg"
                        alt="checkmark"
                      />
                    </span>
                    <span>5 Categories, 18 Topics</span>
                  </li>
                  <li>
                    <span
                      className={`${style.promo__list__icon} ${style.promo__list__icon__html}`}
                    >
                      <img
                        src="/resources/img/icons/check-solid.svg"
                        alt="checkmark"
                      />
                    </span>
                    <span>Detailed explanations</span>
                  </li>
                  <li>
                    <span
                      className={`${style.promo__list__icon} ${style.promo__list__icon__html}`}
                    >
                      <img
                        src="/resources/img/icons/check-solid.svg"
                        alt="checkmark"
                      />
                    </span>
                    <span>More effective than courses</span>
                  </li>
                  <li>
                    <span
                      className={`${style.promo__list__icon} ${style.promo__list__icon__html}`}
                    >
                      <img
                        src="/resources/img/icons/check-solid.svg"
                        alt="checkmark"
                      />
                    </span>
                    <span>Optimized for tablets and mobiles</span>
                  </li>
                </ul>
                <div
                  className={style.promo__buttons}
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <a href="https://app.nursingexampro.com/#/auth/register" className="op__btn mr-3">
                    Sign Up
                    {/* <i className="fe fe-check-circle ml-2 align-middle" /> */}
                  </a>
                  <a href="#more" className="op__btn op__btn--lightDark">
                    More Info
                  </a>
                </div>
              </div>
              <div className="col-md-6 text-center text-md-right">
                <div
                  className={`${style.promo__img}`}
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img
                    src="/resources/img/nep_hero.png"
                    alt="NursingExamPro.com"
                    // style={{visibility: "hidden"}}
                    width="100%"
                  />                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
