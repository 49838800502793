import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className="op__block-padded" id="recommended">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-12">
            <div
              className={style.rec__item}
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className={style.rec__icon}>
                <i className="fe fe-star"></i>
              </div>
              <div className="mb-2">
                <h5>NCLEX Content</h5>
              </div>
              <div>
                Are you preparing for the National Council Licensure Examination (NCLEX)
                to become a Registered Nurse? Then sign up and get access to our question
                bank. All of our questions are categorized according to the official NCLEX
                topics defined by the
                <a href="https://www.ncsbn.org/"> National Council of State Boards of Nursing (NCSBN)</a>
                .
              </div>
            </div>
          </div>
          {/* <div className="col-sm-4 col-12">
            <div
              className={style.rec__item}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className={style.rec__icon}>
                <i className="fe fe-activity"></i>
              </div>
              <div className="mb-2">
                <h5>PNLE Questions</h5>
              </div>
              <div>
                Preparing for the Philippine Nursing Licensure Exam? We have
                thousands of past PNLE questions categorized according to the
                official nursing degree categories. Complete with answers and rationale.
                Start your exam prep today!
              </div>
            </div>
          </div> */}
          <div className="col-sm-6 col-12">
            <div
              className={style.rec__item}
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className={style.rec__icon}>
                <i className="fe fe-zap"></i>
              </div>
              <div className="mb-2">
                <h5>Optimized for Mobile</h5>
              </div>
              <div>
                Our website is optimized for use on mobile and tablet. It's lightning-fast
                and features a dark-mode. Your progress under each topic is tracked in our
                dashboard and you can save questions to revisit them later. All progress is
                automatically saved and synced across your devices.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
