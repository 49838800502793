import React, { useState } from "react"
import style from "./style.module.scss"
import Free from "@/components/Pricing/Free"
import Price from "@/components/Pricing/Price"
import { Button, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

export default () => {
  const [state, setState] = useState({
    term: 'yearly',
    currency: 'usd'
  })

  const updateTerm = (val) => {
    console.log('New value: ' + val)
    setState({ term: val, currency: state.currency })
  }

  const updateCurrency = (val) => {
    console.log('New value: ' + val)
    setState({ term: state.term, currency: val })
  }

  return (
    <div className="op__block-padded" id="pricing">
        <div className="container-fluid">
          <h3 className="text-center mb-5">
            Pricing 
          </h3>
          {/* <div className="row justify-content-center mb-2">
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ToggleButtonGroup
                name="term"
                type="radio"
                className="mr-2"
                aria-label="termGroup"
                defaultValue="yearly"
                onChange = { updateTerm }
              >
                <ToggleButton variant="outline-secondary" value="monthly">Monthly</ToggleButton>
                <ToggleButton variant="outline-secondary" value="yearly">Yearly (-50%)</ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </div> */}
          <div className="row justify-content-center mb-5">
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ToggleButtonGroup
                name="currency"
                type="radio"
                className="mr-2"
                aria-label="currencyGroup"
                defaultValue="usd"
                onChange = { updateCurrency }
              >
                <ToggleButton variant="outline-secondary" value="eur">EUR</ToggleButton>
                <ToggleButton variant="outline-secondary" value="usd">USD</ToggleButton>
                <ToggleButton variant="outline-secondary" value="php">PHP</ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-6 col-lg-3 justify-content-center text-center">
              <div className={style.newcard + " card shadow text-center px-2 mb-4 mx-auto"}>
                <div className="bg-transparent card-header mt-2 pt-4 border-0 px-2">
                  <div className={style.tier}>Basic</div>
                  <div className="border-bottom pb-3">
                    <Free />
                  </div>
                </div>
                <div className="card-body pt-0 px-2 mx-auto">
                  <ul className="list-unstyled mb-4 text-left">
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>100 questions</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Free forever</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>1 device</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Progress dashboard</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Basic support</span>
                    </li>
                    <li>
                      <i className={style.listicon2 + " fe fe-x mr-1"} />
                      <span className={style.listtext}>No custom lists</span>
                    </li>
                    <li>
                      <i className={style.listicon2 + " fe fe-x mr-1"} />
                      <span className={style.listtext}>No practice tests</span>
                    </li>
                  </ul>
                  <a href="https://app.nursingexampro.com/#/auth/register">
                    <Button style={{backgroundColor: "#19D797", borderColor: "#19D797"}}>Sign Up</Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 justify-content-center text-center">
              <div className={style.newcard + " card shadow text-center px-2 mb-4 mx-auto"}>
                <div className="bg-transparent card-header mt-2 pt-4 border-0 px-2">
                  <div className={style.tier}>Plus</div>
                  <div className="border-bottom pb-3">
                    <Price
                      tier = 'plus'
                      term = { state.term }
                      currency = { state.currency }
                    />
                  </div>
                </div>
                <div className="card-body pt-0 px-2 mx-auto">
                  <ul className="list-unstyled mb-4 text-left">
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>2,000 questions</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>1 year access</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>1 device</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Progress dashboard</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Basic support</span>
                    </li>
                    <li>
                      <i className={style.listicon2 + " fe fe-x mr-1"} />
                      <span className={style.listtext}>No custom lists</span>
                    </li>
                    <li>
                      <i className={style.listicon2 + " fe fe-x mr-1"} />
                      <span className={style.listtext}>No practice tests</span>
                    </li>
                  </ul>
                  <a href="https://app.nursingexampro.com/#/auth/register">
                    <Button style={{backgroundColor: "#19D797", borderColor: "#19D797"}}>Sign Up</Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 justify-content-center text-center">
              <div className={style.newcardpro + " card shadow text-center px-2 mb-4 mx-auto"}>
                <span
                  className="h6 w-60 mx-auto px-2 py-1 rounded-bottom text-white shadow-sm"
                  style={{
                    backgroundColor: "#1ad697",
                    fontFamily: "system-ui, Arial, serif",
                    fontSize: "0.8rem",
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-50%)",
                    width: "70%",
                    zIndex: "10"
                  }}
                >
                  MOST POPULAR
                </span>
                <div className="bg-transparent card-header mt-2 pt-4 border-0 px-2">
                  <div className={style.tier}>Pro</div>
                  <div className="border-bottom pb-3">
                    <Price
                      tier = 'pro'
                      term = { state.term }
                      currency = { state.currency }
                    />
                  </div>
                </div>
                <div className="card-body pt-0 px-2 mx-auto">
                  <ul className="list-unstyled mb-4 text-left">
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>5,000 questions</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>1 year access</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>2 devices</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Progress dashboard</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Premium support</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Custom lists</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Practice tests</span>
                    </li>
                  </ul>
                  <a href="https://app.nursingexampro.com/#/auth/register">
                    <Button style={{backgroundColor: "#19D797", borderColor: "#19D797"}}>Sign Up</Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 justify-content-center text-center">
              <div className={style.newcard + " card shadow text-center px-2 mb-4 mx-auto"}>
                <div className="bg-transparent card-header mt-2 pt-4 border-0 px-2">
                  <div className={style.tier}>Unlimited</div>
                  <div className="border-bottom pb-3">
                    <Price
                      tier = 'unlimited'
                      term = { state.term }
                      currency = { state.currency }
                    />
                  </div>
                </div>
                <div className="card-body pt-0 px-2 mx-auto">
                  <ul className="list-unstyled mb-4 text-left">
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>10,000+ questions</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>1 year access</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Unlimited devices</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Progress dashboard</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Premium support</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Custom lists</span>
                    </li>
                    <li>
                      <i className={style.listicon + " fe fe-check mr-1"} />
                      <span className={style.listtext}>Practice tests</span>
                    </li>
                  </ul>
                  <a href="https://app.nursingexampro.com/#/auth/register">
                    <Button style={{backgroundColor: "#19D797", borderColor: "#19D797"}}>Sign Up</Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
