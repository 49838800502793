import React from "react"
import MainLayout from "@/layouts/MainLayout"
import Promo from "@/components/Promo"
// import Carousel from "@/components/Carousel"
import About from "@/components/About"
import Recommended from "@/components/Recommended"
import Pricing from "@/components/Pricing"
import Reviews from "@/components/Reviews"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default () => {
  return (
    
    <MainLayout>
      <GatsbySeo
        title='NCLEX Practice Questions'
        titleTemplate='NursingExamPro.com | %s'
        description='Over 10,000 NCLEX practice questions with answers and rationales.'
      />
      <Promo />
      {/* <Carousel /> */}
      <Recommended />
      <About />
      <Reviews />
      <Pricing />
    </MainLayout>
  )
}
