import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className="op__block-padded" id="about">
      <div className="container-fluid">
        <h3 className="text-center mb-4">
          How it Works 
        </h3>
        <h5 className="text-center mt-5 mb-4">
          NCLEX and PNLE Questions and Answers 
        </h5>
        <p className="text-muted mt-4 mb-4">
          If you're preparing for the NCLEX or PNLE exam, then we have the right content for you.
          We have over ten thousand PNLE and NCLEX test questions and answers that we collected from
          past NCLEX and PNLE exams. All our nursing practice questions and answers have been reviewed
          by licensed nursing instructors.
        </p>
        <p className="text-muted mt-4 mb-4">
          Sign up now to get access to our nursing question bank. Our nursing board exam questions
          include correct answers and rationales to help you prepare for the NCLEX exam, PNLE exam or
          any other nursing exams.
        </p>
        <h5 className="text-center mt-5 mb-4">
          5 Nursing Categories – 18 Nursing Topics
        </h5>
        <p className="text-muted mt-4 mb-4">
          All PNLE and NCLEX nursing exam quesions are grouped by their respective topics and easily
          accessible through our progress dashboard and menus. Premium members can download nursing
          exam questions and answers as pdf-files.
        </p>
        <h5 className="text-center mt-5 mb-4">
          Progress Dashboard
        </h5>
        <p className="text-muted mt-4 mb-4">
          The dashboard shows the learner progress overall and by nursing topic.
        </p>
        <p>
          <img
            src="/resources/img/screen1.png"
            alt="NursingExamPro.com"
            // style={{visibility: "hidden"}}
            width="100%"
          />
        </p>
        <h5 className="text-center mt-5 mb-4">
          Question Cards
        </h5>
        <p className="text-muted mt-4 mb-4">
          All nursing questions are shown on structured cards with tabs for answers and rationales.
          Question cards show whether a question has already been viewed. Questions can be saved to
          custom lists for later review.
        </p>
        <p className="text-muted mt-4 mb-4">
          <img
            src="/resources/img/screen2.png"
            alt="NursingExamPro.com"
            // style={{visibility: "hidden"}}
            width="100%"
          />
        </p>
      </div>
    </div>
  )
}
