import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className="op__block-padded">
      <div className="container-fluid">
        <h3 className="text-center mb-4">
          Hear What Our Customers Say 
        </h3>
        <p className="op__descr text-center text-muted mb-5">
          We helped countless students pass their nursing exams and become registered nurses.
          Read here what some of them had to say about our content offering.
        </p>
        <div className="row">
          <div className="col-md-4">
            <div
              className={style.reviews__item}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className={style.reviews__title}>John Mark Reyes</div>
              <div className={`${style.reviews__company} text-muted`}>
                Registered Nurse, Philippines
              </div>
              <div className={style.reviews__icon}>
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
              </div>
              <p>
                I was preparing for my local board exam and realized that many review centers aren't
                focused enough on actual exam questions. I'm so glad I found this website. They have
                thousands of real exam questions. So many of the questions and answers I studied here
                were in my board exam! Wow!
              </p>
              <p>
                <strong>Valuable and highly recommended!</strong>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={style.reviews__item}
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className={style.reviews__title}>Justine Ledger</div>
              <div className={`${style.reviews__company} text-muted`}>
                Registered Nurse, USA
              </div>
              <div className={style.reviews__icon}>
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
              </div>
              <p>
                Becoming a nurse had always been my dream, and now it's a reality.
                If only I had known about this website earlier. Their
                questions cover all relevant nursing exam topics. When I was sitting
                in my final exam, I was surprised how many questions were almost identical
                to the content here. Nice!
              </p>
              <p>
                <strong>A must for all nursing students!</strong>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`${style.reviews__item} mb-0`}
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className={style.reviews__title}>Catherine Summer</div>
              <div className={`${style.reviews__company} text-muted`}>
                Registered Nurse, UK
              </div>
              <div className={style.reviews__icon}>
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
                <img
                  src="/resources/img/icons/star-solid.svg"
                  alt="Mobile-Ready"
                />
              </div>
              <p>
                I now work as a nurse in an NHS hospital in London. Thank you,
                NursingExamPro.com! All the questions and answers with explanations
                on your site were a huge help. One always wonders before an exam,
                'What could they ask?' Now we can finally know most of the questions
                in advance!
              </p>
              <p>
                <strong>The subscription is worth every penny!</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
