import React from "react"
import style from "./style.module.scss"

export default function Free() {
  return (
    <div>
      <div className={style.price}>
        <span>
          FREE
        </span>
      </div>
      <div className={style.term + " pt-1 text-muted"}>
        <span>FOREVER</span>
      </div>
    </div>
  )
}
